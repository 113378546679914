import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Img from "gatsby-image"

import { FaBook, FaUsers } from "react-icons/fa"

const AboutPage = ({data, ...props}) => (
  <Layout pathname={props.location.pathname} hero="services.jpg">
    <SEO title="About Us" />
    <h1>About Burmpro</h1>

        <h2>
          <FaBook className="inline mr-2" />
          Our Story
        </h2>

        <p>Burmpro is a Melbourne based building company, founded in 2012 by brothers Dave & Tim Burmeister.</p>

        <p>With over 30 years combined experience in the industry, we specialize in all types of residential construction, project management & commercial dental clinic fit outs.</p>

        <p>Over the years we have teamed up with leading architects & designers to deliver complex & high end projects, consistently on time & on budget, which has stamped our mark on the industry throughout the state.</p>

        <p>As a private family owned business, we truly understand the need for trust, reliability and the highest quality of service & communication, which we promise to deliver in every project.</p>

        <p>Burmpro is licensed as a Registered Domestic Builder (Unlimited). This registration is the highest available in the domestic category, enabling us to be responsible for carrying out, managing or arranging the carrying out of all components of domestic building work for the construction, renovation, improvement or maintenance of a home.</p>

        <p>
          Please see more of our <Link to="/services">quality work</Link>.
        </p>

      <h2><FaUsers className="inline mr-2" />Directors</h2>

      <div class="grid lg:grid-cols-4">

        <div className="mb-8">
          <Img
            fluid={data.daveImage.childImageSharp.fluid}
            className="w-64"
            alt=""
          />
        </div>

        <div className="col-span-3">
          
          <p><strong>Dave Burmeister</strong> is one of our founding directors at Burmpro. Dave joined the building industry after successfully completing his VCE, and has over 16 years of experience through working with award winning architectural builders, large commercial construction & rural & industrial commercial builders, resulting in a wide range of expertise & knowledge of all aspects of building.</p>

  ​        <p>Dave is personable and easy going, and enjoys working closely with clients & designers. Seeing their dream project come to life and the excitement that it brings them, gives him the greatest satisfaction of being in the construction sector.</p>

        </div>

        <div className="mt-6">
          <Img
            fluid={data.timImage.childImageSharp.fluid}
            className="w-64"
            alt=""
          />
        </div>

        <div className="col-span-3 mt-6">
          
          <p><strong>Tim Burmeister</strong> is a founding director, with 15+ years of carpentry & industry experience since successfully completing VCE. Tim has cut his teeth working at multiple high profile & boutique houses around the inner suburbs of Melbourne, running carpentry teams and teaching his effective solutions along the way.</p>

          <p>Tim excels in problem solving, and the more intricate job & tasks which arise through building, helping our team have confidence to tackle the projects that other builders arent willing to.</p>

        </div>

      </div>

  </Layout>
)

export default AboutPage


export const query = graphql`
  query {
    daveImage: file(relativePath: { eq: "about/dave.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    timImage: file(relativePath: { eq: "about/tim.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
